/**
 * Code formatting
 */
.pl-c {
  color: #6a737d;
}

.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}

.pl-e,
.pl-en {
  color: #6f42c1;
}

.pl-smi,
.pl-s .pl-s1 {
  color: #24292e;
}

.pl-ent {
  color: #22863a;
}

.pl-k {
  color: #d73a49;
}

.pl-s,
.pl-pds,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sre,
.pl-sr .pl-sra {
  color: #032f62;
}

.pl-v,
.pl-smw {
  color: #e36209;
}

.pl-bu {
  color: #b31d28;
}

.pl-ii {
  color: #fafbfc;
  background-color: #b31d28;
}

.pl-c2 {
  color: #fafbfc;
  background-color: #d73a49;
}

.pl-c2::before {
  content: "^M";
}

.pl-sr .pl-cce {
  font-weight: bold;
  color: #22863a;
}

.pl-ml {
  color: #735c0f;
}

.pl-mh,
.pl-mh .pl-en,
.pl-ms {
  font-weight: bold;
  color: #005cc5;
}

.pl-mi {
  font-style: italic;
  color: #24292e;
}

.pl-mb {
  font-weight: bold;
  color: #24292e;
}

.pl-md {
  color: #b31d28;
  background-color: #ffeef0;
}

.pl-mi1 {
  color: #22863a;
  background-color: #f0fff4;
}

.pl-mc {
  color: #e36209;
  background-color: #ffebda;
}

.pl-mi2 {
  color: #f6f8fa;
  background-color: #005cc5;
}

.pl-mdr {
  font-weight: bold;
  color: #6f42c1;
}

.pl-ba {
  color: #586069;
}

.pl-sg {
  color: #959da5;
}

.pl-corl {
  text-decoration: underline;
  color: #032f62;
}
